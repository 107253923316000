<template>
  <div>
    <div id="container" :class="[loading && 'overlay overlay-block']">
      <div
        v-if="isShipmentFileReady"
        class="d-flex flex-column align-items-end"
      >
        <!--begin::Shipment Results-->
        <div
          class="
            d-flex
            flex-column
            align-items-center
            border border-3 border-warning
            rounded
            w-35-md
          "
        >
          <h6 class="m-4">Your Shipment Results</h6>
          <ul class="list-group list-group-flush w-100">
            <h5>
              <a
                :href="
                  '/upsLabels/labelPrintFiles/' +
                  createShipmentLabelsResponse.mergedPdfLabelsFile
                "
                class="list-group-item list-group-item-action"
                target="_blank"
                >Download UPS Print Label PDF
                <i class="bi bi-box-arrow-in-up-right"></i>
              </a>
            </h5>
            <li
              class="
                list-group-item list-group-item-action
                d-flex
                justify-content-between
                align-items-center
              "
            >
              # of Packages<span class="badge bg-primary rounded-pill">{{
                createShipmentLabelsResponse.numberOfPackages
              }}</span>
            </li>
            <li
              class="
                list-group-item list-group-item-action
                d-flex
                justify-content-between
                align-items-center
              "
            >
              Failed Shipments<span class="badge bg-primary rounded-pill">{{
                createShipmentLabelsResponse.failedShipments.length
              }}</span>
            </li>
            <li
              v-for="(
                item, index
              ) in createShipmentLabelsResponse.failedShipments"
              :key="item"
              :id="'failed_' + index"
              class="list-group-item"
            >
              <small>{{ item }}</small>
            </li>
            <h5>
              <a
                :href="
                  '/pdfs/pickTickets/' +
                  createShipmentLabelsResponse.pickTicketFilename
                "
                class="list-group-item list-group-item-action"
                target="_blank"
                >Download Pick Ticket
                <i class="bi bi-box-arrow-in-up-right"></i>
              </a>
            </h5>
          </ul>
        </div>
        <!--end::Shipment Results-->
      </div>
      <div class="d-flex flex-row justify-content-between my-4">
        <!--begin::Search-->
        <div d-flex flex-column>
          <p class="m-0">
            <small> Search by Amazon Order Id or Buyer email</small>
          </p>
          <div class="d-flex align-items-center position-relative my-1">
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <inline-svg src="../media/icons/duotune/general/gen021.svg" />
            </span>
            <input
              type="text"
              v-model="search"
              class="form-control form-control-solid w-250px ps-15"
              placeholder="Search Orders"
            />
          </div>
        </div>
        <!--end::Search-->
        <!--begin::Batch Print-->
        <div>
          <p class="m-0"># of Orders: {{ tableData.length }}</p>
          <button
            class="btn btn-primary btn-sm"
            type="submit"
            ref="createShipmentsButton"
            id="updateShippingStatusBtn"
            @click="createShipments()"
          >
            <span class="indicator-label"
              >Create Shipments on Selected Orders</span
            >
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
      <div v-if="getItems.length > 0" class="pb-2">
        <!--Row-->
        <div
          class="
            d-flex
            flex-column flex-md-row
            justify-content-around
            border-bottom border-gray-400
            pb-5
            pt-4
          "
          v-for="(item, index) in getItems"
          :key="item.fBM_OrderId"
          :id="'order_' + index"
        >
          <!-- Amazon Order Id -->
          <div class="d-flex align-items-start w-25-md">
            <div class="d-flex flex-column">
              <p class="m-0 text-gray-900 fw-bold fs-6">
                {{ item.amazonOrderId }}
              </p>
              <div d-flex flex-column>
                <p class="m-0">
                  <small>{{ item.buyerName }}</small>
                </p>
                <p class="m-0">
                  <small
                    >{{ item.shipCity }}, {{ item.shipStateOrRegion }}</small
                  >
                </p>
                <p class="m-0">
                  <small
                    >{{ item.shipPostalCode }},
                    {{ item.shipCountryCode }}</small
                  >
                </p>
              </div>
              <p class="m-0">
                <small>Purchased: {{ d(item.purchaseDate) }}</small>
              </p>
              <p class="m-0">
                <small>Latest Ship Date: {{ d(item.latestShipDate) }}</small>
              </p>
              <p class="py-1">
                <span v-show="item.addressVerified" class="badge badge-success"
                  >Address Verified</span
                ><span v-show="!item.addressVerified" class="badge badge-danger"
                  >Address Not Verified</span
                >
              </p>
            </div>
          </div>
          <!-- End Amazon Order Id -->
          <!-- Order Line Items -->
          <div class="d-flex flex-column w-40-md">
            <div
              class="d-flex flex-column"
              v-for="(lineItem, j) in item.fbM_OrderLineItems"
              :key="j"
            >
              <p class="m-0">
                <strong>{{ lineItem.title }}</strong>
              </p>
              <p class="m-0">
                <small>{{ lineItem.sellerSKU }}</small>
              </p>
            </div>
            <div>
              <p class="m-0 text-gray-900 fw-bold fs-6 pt-3">
                Item subtotal: ${{ item.orderTotal }}
              </p>
            </div>
          </div>
          <!-- End Order Line Items -->
          <!-- Right Section -->
          <div class="d-flex flex-column">
            <div class="d-flex flex-column align-items-end py-1">
              <p class="m-0">
                <strong>Packages ({{ item.fbM_Packages.length }})</strong>
              </p>
              <p class="m-0">#Non-Pkg Skus ({{ item.numNonPkgReadySkus }})</p>
              <p v-if="item.numNonPkgReadySkus > 0" class="m-0">
                Skus: {{ item.nonPkgReadySkus }}
              </p>
            </div>
            <div class="d-flex flex-column align-items-end py-3">
              <div>
                <label
                  class="form-check-label pe-2"
                  :for="'flexCheckDefault' + index"
                >
                  Create Shipment
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'flexCheckDefault' + index"
                  :value="item.fbM_OrderId"
                  v-model="createShipmentLabelsRequest.checkedOrders"
                />
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-start py-1">
              <span
                v-show="item.readyForQuickBooksImport"
                class="badge badge-success"
                >Ready for QB Import</span
              ><span
                v-show="!item.readyForQuickBooksImport"
                class="badge badge-danger"
                >Not Ready for QB Import</span
              >
            </div>
            <div class="d-flex justify-content-end align-items-start py-1">
              <span
                v-show="item.importedToQuickBooks"
                class="badge badge-success"
                >Imported to QB</span
              ><span
                v-show="!item.importedToQuickBooks"
                class="badge badge-danger"
                >Not Imported to QB</span
              >
            </div>
            <div class="d-flex justify-content-end align-items-start py-1">
              <span
                v-show="item.orderStatus === 'Shipped'"
                class="badge badge-success"
                >Shipped</span
              ><span
                v-show="item.orderStatus === 'Unshipped'"
                class="badge badge-danger"
                >Unshipped</span
              >
              <span
                v-show="item.orderStatus === 'Canceled'"
                class="badge badge-dark"
                >Canceled</span
              >
            </div>

            <p
              v-if="item.shippingLabelPrinted"
              class="d-flex flex-column align-items-end snappy-primary-red"
            >
              <strong><small>Package labels have been created.</small></strong>
            </p>
          </div>
          <!-- End Right Section -->
        </div>
        <!-- End Row -->
      </div>
      <div v-else>
        <p>There are currently no orders to create shipment for.</p>
      </div>
      <!-- Loading -->
      <div
        v-if="loading"
        class="overlay-layer card-rounded bg-dark bg-opacity-5"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- End Loading -->
    </div>

    <div v-if="getItems.length > 0" class="row">
      <div
        class="
          col-sm-12 col-md-5
          d-flex
          align-items-center
          justify-content-center justify-content-md-start
        "
      >
        <div
          v-if="enableItemsPerPageDropdown"
          class="dataTables_length"
          id="kt_customers_table_length"
        >
          <label
            ><select
              name="kt_customers_table_length"
              class="form-select form-select-sm form-select-solid"
              @change="setItemsPerPage"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select></label
          >
        </div>
      </div>
      <div
        class="
          col-sm-12 col-md-7
          d-flex
          align-items-center
          justify-content-center justify-content-md-end
        "
      >
        <el-pagination
          v-model:current-page="pagination.page"
          @current-change="currentPageChange"
          :page-size="pagination.rowsPerPage"
          layout="prev, pager, next"
          :total="pagination.total"
          :hide-on-single-page="true"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  PropType,
  ComputedRef,
} from "vue";
import { useI18n } from "vue-i18n";
import { FbmOrder } from "@/core/types/FbmOrderTypes";
import {
  ShippingStatusChangeRequests,
  ShippingPackageInfoDto,
  ShippingStatusChangeRequestDto,
} from "@/core/types/SP_API_ShippingTypes";
import {
  CreateShipmentLabelsRequest,
  CreateShipmentLabelsResponse,
} from "@/core/types/ShipmentTypes.js";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "fbm-package-list-table",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    tableData: {
      type: Object as PropType<Array<FbmOrder>>,
      required: true,
    },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 25 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    const store = useStore();
    const data = ref(props.tableData);
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });
    const { d } = useI18n();
    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const createShipmentsButton = ref<HTMLButtonElement | null>(null);
    const isShipmentFileReady = ref(false);
    const shippingPackageInfoDto = ref<ShippingPackageInfoDto>({
      orderItemId: "",
      trackingNumber: "",
    });
    const shippingStatusChangeRequestDto = ref<ShippingStatusChangeRequestDto>({
      amazonOrderId: "",
      packages: [] as ShippingPackageInfoDto[],
    });
    const shippingStatusChangeRequests = ref<ShippingStatusChangeRequests>({
      shippingStatusRequestList: [],
    });
    const shippingStatusRequests = ref([] as ShippingStatusChangeRequestDto[]);
    const allOrdersSelected = ref(false);
    const createShipmentLabelsRequest = ref<CreateShipmentLabelsRequest>({
      checkedOrders: [],
      hasSelectedOrders: false,
    });
    const createShipmentLabelsResponse: ComputedRef<CreateShipmentLabelsResponse> =
      computed(() => {
        return store.getters.getCreateShipmentLabelsResponse;
      });

    watch(data.value, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      } else {
        pagination.value.total = data.value.length;
      }
    });

    onMounted(() => {
      pagination.value.total = props.total ? props.total : data.value.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems: ComputedRef<Array<FbmOrder>> = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return filterData.value;
      } else {
        const clone = JSON.parse(JSON.stringify(filterData.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });
    const search = ref("");
    const filterData = computed(() => {
      return data.value.filter((user) => {
        return (
          user.buyerEmail.toLowerCase().indexOf(search.value.toLowerCase()) !=
            -1 || user.amazonOrderId.indexOf(search.value) != -1
        );
      });
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    function orderSelected(amazonOrderId, packages) {
      shippingStatusChangeRequestDto.value.amazonOrderId = amazonOrderId;
      shippingStatusChangeRequestDto.value.packages = packages;
      shippingStatusRequests.value.push(shippingStatusChangeRequestDto.value);
    }

    function createShipments() {
      isShipmentFileReady.value = false;

      shippingStatusChangeRequests.value.shippingStatusRequestList =
        shippingStatusRequests.value;
      if (createShipmentLabelsRequest.value.checkedOrders.length > 0) {
        createShipmentLabelsRequest.value.hasSelectedOrders = true;
      }
      store
        .dispatch(Actions.CREATE_SHIPMENTS, createShipmentLabelsRequest.value)
        .then(() => {
          //
          Swal.fire({
            text: "Create Shipments Are Completed",
            icon: "success",
            showCloseButton: true,
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-info",
            },
          });
        })
        .catch(() => {
          Swal.fire({
            text: createShipmentLabelsResponse.value.errors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Okay",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (createShipmentsButton.value) {
        // eslint-disable-next-line
        createShipmentsButton.value!.disabled = true;
        // Activate indicator
        createShipmentsButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    watch(createShipmentLabelsResponse, () => {
      if (createShipmentLabelsResponse.value.success) {
        isShipmentFileReady.value = true;
      }

      //Deactivate indicator
      createShipmentsButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      createShipmentsButton.value!.disabled = false;
    });

    return {
      search,
      filterData,
      createShipmentsButton,
      allOrdersSelected,
      shippingPackageInfoDto,
      shippingStatusChangeRequestDto,
      shippingStatusChangeRequests,
      shippingStatusRequests,
      createShipmentLabelsRequest,
      createShipmentLabelsResponse,
      isShipmentFileReady,
      pagination,
      currentPageChange,
      getItems,
      setItemsPerPage,
      orderSelected,
      createShipments,
      d,
    };
  },
});
</script>

<style lang="scss">
/* Responsive Code */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768 and up)*/
@media (min-width: 768px) {
  .w-25-md {
    width: 25% !important;
  }
  .w-35-md {
    width: 35% !important;
  }
  .w-40-md {
    width: 40% !important;
  }
  .w-45-md {
    width: 45% !important;
  }
}

/* Large devices (desktops, 992 and up)*/
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200 and up)*/
@media (min-width: 1200px) {
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
